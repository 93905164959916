@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind apply;

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

* {
  box-sizing: border-box;
  font-family: 'Space Grotesk', sans-serif;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

.swiper-button-next,
.swiper-button-prev {
  @apply left-6 absolute shadow-xl top-10 bg-primary rounded-full w-8 h-8 #{!important};
  &::after {
    @apply text-sm text-light #{!important};
  }
}

.swiper-button-next {
  @apply left-16 #{!important};
}

.swiper-pagination-bullet {
  @apply w-2 h-2 bg-amber-400 #{!important};
}

.background-animate {
  background-size: 400%;
  animation: AnimationName 10s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.eq-bar {
  transform: scale(1, -1) translate(0, -24px);
}

.is-playing .eq-bar--1 {
  animation-name: short-eq;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

.is-playing .eq-bar--1 {
  animation-name: short-eq;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

.is-playing .eq-bar--2 {
  animation-name: tall-eq;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.17s;
}

.is-playing .eq-bar--3 {
  animation-name: short-eq;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.34s;
}

@keyframes short-eq {
  0% {
    height: 8px
  }

  50% {
    height: 4px
  }

  100% {
    height: 8px
  }
}

@keyframes tall-eq {
  0% {
    height: 16px
  }

  50% {
    height: 6px
  }

  100% {
    height: 16px
  }
}